export class Product {
  id;
  name;
  name_marketing;
  code;
  ancient_code;
  generated_code;
  image;
  category_id;
  brand;
  type = "simple";
  point_of_supply;
  imported;
  purchase_price;
  purchase_price_ht;
  price;
  price_ht;
  purchase_tva = 0;
  tva = 0;
  price = null;

  barcodes = [new Barcode(null, true)];
  main_provider = new Provider();
  secondary_providers = [];
  product_info = new ProductInfo();
  short_description;
  description;
  special_category_ids = [];
  express_status = true;
  /** @type {{id; name; qte_in_hub}[]} */
  hubs;
  /** @type {{id; name; sku; qte;}[]} */
  variants;
}

export class Barcode {
  code;
  is_main;
  constructor(code, is_main) {
    this.code = code;
    this.is_main = is_main;
  }
}

export class Provider {
  id;
  status = false;
}

export class ProductInfo {
  item_net_weight = null;
  item_net_weight_unit = null;
  item_gross_weight = null;
  item_gross_weight_unit = null;
  item_net_volume = null;
  item_net_volume_unit = null;
  item_gross_volume = null;
  item_gross_volume_unit = null;
  package_units = null;
  package_width = null;
  package_depth = null;
  package_height = null;
  package_net_weight = null;
  package_net_weight_unit = null;
  package_gross_weight = null;
  package_gross_weight_unit = null;
  palette_units = null;
  palette_width = null;
  palette_depth = null;
  palette_height = null;
  palette_net_weight = null;
  palette_net_weight_unit = null;
  palette_gross_weight = null;
  palette_gross_weight_unit = null;
}
